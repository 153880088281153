const state = {
  systemTitle: "药研有度运营管理平台",
  menuData: [
    {
      id: "1", title: "访视费用申请", show: true, icon: "iconfont icon-caiwuguanli", children: [
        { id: "1-0", title: "待处理申请", icon: "", path: "/pending", children: [] },
        { id: "1-1", title: "已处理申请", icon: "", path: "/processed", children: [] },
      ]
    }
  ],
  baseLine: [
    {
      value: null, text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "等待研究者审核", key: null }
      ]
    },
    {
      value: "-1", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "取消申请", key: "dt_update" }
      ]
    },
    {
      value: "1", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "等待下一级审核", key: null }
      ]
    },
    {
      value: "3", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者驳回", key: "dt_researcher_verify", remark: "txt_researcher_verify_remarks" }
      ]
    },
    {
      value: "6", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "CRA审核完成", key: "dt_cra_verify" },
        { title: "等待申办方审核", key: null }
      ]
    },
    {
      value: "7", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "CRA驳回", key: "dt_cra_verify", remark: "txt_cra_verify_remarks" }
      ]
    },
    // 截断
    {
      value: "2", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "申办方审核完成", key: "dt_sponsor_verify" },
        { title: "等待后台发放", key: null }
      ]
    },
    {
      value: "4", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "申办方驳回", key: "dt_sponsor_verify", remark: "txt_sponsor_verify_remarks" }
      ]
    },
    {
      value: "5", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "申办方审核完成", key: "dt_sponsor_verify" },
        { title: "发放完成", key: "dt_update" }
      ]
    },
    {
      value: "62", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "CRA审核完成", key: "dt_cra_verify" },
        { title: "申办方审核完成", key: "dt_sponsor_verify" },
        { title: "等待后台发放", key: null }
      ]
    },
    {
      value: "64", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "CRA审核完成", key: "dt_cra_verify" },
        { title: "申办方驳回", key: "dt_sponsor_verify", remark: "txt_sponsor_verify_remarks" }
      ]
    },
    {
      value: "65", text: [
        { title: "发起申请", key: "dt_compensation_application" },
        { title: "研究者审核完成", key: "dt_researcher_verify" },
        { title: "CRA审核完成", key: "dt_cra_verify" },
        { title: "申办方审核完成", key: "dt_sponsor_verify" },
        { title: "发放完成", key: "dt_update" }
      ]
    }
  ]
}

export default {
  state
};